@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');


ui.ant {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
}

.ant-menu{
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  color:white;
}

.ant-table.css-dev-only-do-not-override-42nv3w {
  font-size: 10.4px;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination{
  margin: 20px;
  transform: translateX(-115px);
}

/* .ant-layout-content{
  height: 100%
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
    font-size: 12px;
    /* height: 100vh; */
    /* height: 100% !important; */
    height: 100%;
    background: None;
    min-height: 100vh;
  }
  
  .ant-layout-header {
    background-color:white;
    color: black!important;
  }

  /* .ant-layout-content {
    /* height: 100% !important; */
  /* } */
  
  /* .ant-layout.ant-layout-has-sider {
    height: 100% !important;
  } */

  .ant-input.css-dev-only-do-not-override-42nv3w.ant-input-filled{
    margin-left: 15px;
    height: 100%;
  }

  .chatbot-form{
    margin: 15px;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 7px;
  }

  .ant-col {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 7px;
  }

  .ant-form-item .ant-form-item-label >label{
    font-size: 10px;
  }


  /* .ant-layout .ant-layout-sider-children {
    height: 850px;
  } */
/* 
  .ant-layout .css-dev-only-do-not-override-42nv3w{
    height: 100%;
  } */

  .ant-layout-content.css-dev-only-do-not-override-42nv3w {
    /* margin-top: 10px; */
    background-color: '#efeceb';
  }

  .ant-row.css-dev-only-do-not-override-42nv3w{
    width: 350px;
    padding: 15px;
    /* border: 2px solid rgb(226, 226, 226); */
    /* border-radius: 5%; */

  }

  .ant-statistic-title{
    font-family: "Noto Sans KR", sans-serif;
    font-size: 7px;
    color: black;

  }

  .css-dev-only-do-not-override-42nv3w .ant-statistic .ant-statistic-title {
    color: black;
  }
  

  .ant-statistic-content{
    font-family: "Noto Sans KR", sans-serif;
    font-size: 7px;

  }

  .ant-menu-submenu-arrow{
    color:white;
  }

  :where(.css-dev-only-do-not-override-42nv3w).ant-menu .ant-menu-submenu-expand-icon,
:where(.css-dev-only-do-not-override-42nv3w).ant-menu .ant-menu-submenu-arrow {
  color: white !important; 
}

.ant-modal-confirm .ant-modal-confirm-content{
  font-family: "Noto Sans KR", sans-serif;
}

  

 

