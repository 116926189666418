body {
    background-color:#eff1f6;
    font-family: "Noto Sans KR", sans-serif;
}

.main {
    background-color: #FFFFFF;
    width: 600px;
    height: 500px;
    margin: 7em auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    font-family: "Noto Sans KR", sans-serif;
}

.sign {
    padding-top: 40px;
    color: #8C55AA;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;
    font-size: 18px;
}

.un {
    margin: auto;
width: 76%;
color: rgb(38, 50, 56);
font-weight: 700;
font-size: 14px;
letter-spacing: 1px;
background: rgba(136, 126, 126, 0.04);
padding: 10px 20px;
border: none;
border-radius: 20px;
outline: none;
box-sizing: border-box;
border: 2px solid rgba(0, 0, 0, 0.02);
margin-bottom: 50px;
text-align: center;
margin-bottom: 27px;
font-family: "Noto Sans KR", sans-serif;
}

form.form1 {
    padding-top: 40px;
}

.pass {
        width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin: auto;
    display: flex;
    justify-content : center;
    margin-bottom: 27px;
    font-family: "Noto Sans KR", sans-serif;
    
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.un:focus, .pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
    
}

.submit {
  cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background: linear-gradient(to right,#ec91f8,#ea65fb);
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: "Noto Sans KR", sans-serif;
    margin-left: 49%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
    transform: translateX(-58px);
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #E1BEE7;
    padding-top: 15px;
}


@media (max-width: 600px) {
    .main {
        border-radius: 0px;
    }
}
    
    
