@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.ant-layout{
  font-family: "Noto Sans KR", sans-serif;
  
}

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a{
  font-size: 12px;
}

.title-area {
  /* border-bottom: 3px solid rgb(226,226,226); */
  font-family: "Noto Sans KR", sans-serif;
  
}

.main-title {
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  /* &icon {
    fill: #c3cfd8;
    width: 3.125rem;
    height: 3.125rem;
    vertical-align: -0.75rem;
    margin-right: 1.125rem;
  } */
    
  }

  .sub-title {
  text-align: left;
  color: #000000;
  font-size: 13.5px;
  }

  .tech-area {
    margin-top: 15px;
    font-family: "Noto Sans KR", sans-serif !important;
    /* border: 3px solid rgb(226,226,226); */
    /* padding: 30px; */
  }

  .circle {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid rgb(174, 182, 190);
  }


  /* 챗봇영역 */
  .chatCreate-area{
    margin: 35px;
    /* width: 720px;
    height: 555px; */
    border: 2px solid rgb(226, 226, 226);
    display:flex;
    /* float: left; */
    /* justify-content:center;
    align-items: center; */
  }
  /* 챗봇영역 */

  /* 챗봇 */
  .react-chatbot-kit-chat-container {
    position: relative;
    /* width: 275px; */
  }
  

  .ocr-content{
    margin: 35px;
    width: 300px;
    height: 400px;
    border: 2px solid rgb(226, 226, 226);
    border-radius: 5%;
    display:flex;
    text-align: center;
  }

  .ocr-content_result{
    margin: 35px;
    width: 750px;
    height: 500px;
    border: 2px solid rgb(226, 226, 226);
    border-radius: 5%;
    display:flex;
    text-align: center;
  }

  .container-content{
    /* background-color:rgb(221, 228, 238); */
    border-radius: 3%;
    /* height: 200px; */
    padding: 25px;
  }

  .search-area{
    width: 100%;
    height: 12%;
    padding:3px;
  }

  .search-menu {
    display: grid; 
    place-items: center; 
    background-color: rgb(236, 241, 247);
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
    border-top: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    align-items: center; 
}


  .menu-item{
    padding-left: 13px;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    vertical-align: middle;
    border-top: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
  }

  .default-errorMessage{
    font-size: 9px;
    color:red;
  }

  /* 파일 업로드 */
  .filebox .upload-name {
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    vertical-align: middle;
    border: 1px solid #dddddd;
    width: 78%;
    color: #999999;
}

.filebox label {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  vertical-align: middle;
  background-color: #999999;
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
}

.filebox input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}


/* figma Test */

/* Rectangle 1 */

.test1{
position: absolute;
width: 427px;
height: 621px;

background: #FEF7FF;
}


/* Rectangle 2 */

.test2{position: absolute;
width: 315px;
height: 48px;

background: #FFFFFF;
backdrop-filter: blur(2px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 22px;
}


/* Rectangle 3 */


.test3{
position: absolute;
width: 320px;
height: 365px;

background: #FFFFFF;
backdrop-filter: blur(2px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 22px;
}

/* Rectangle 4 */

.test4{
position: absolute;
width: 117px;
height: 24px;

background: #ABBEFF;
border-radius: 7px;
}

/* Rectangle 5 */

.test5{
position: absolute;
width: 117px;
height: 24px;

background: #ABBEFF;
border-radius: 7px;
}

tspan {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 13px;
}


.react-chatbot-kit-chat-inner-container{
  height: 500px !important;
  border: 'none';
  border-radius:'0 0 36px 36px' ;

}

.react-chatbot-kit-chat-container{
  margin-top: 15px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 10px !important;
}

.td.ant-table-cell {
  font-family: 'Noto Sans KR', sans-serif;
}


.tables {
  font-family: 'Noto Sans KR', sans-serif;
}

.h5 {
  font-size: '12px';
  font-family: 'Noto Sans KR', sans-serif !important;
}

.aside.ant-layout-sider.ant-layout-sider-dark {
  max-width: none;
  width: 180px;
}

.rdw-option-wrapper{
  height: 5px !important;
}

.rdw-block-wrapper{
  height: 5px !important;
}

.ant-table-cell{
  padding:15px !important
}

.ant-card-body{
  padding:5px !important;
}

.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.card1 {
  display: block;
  position: relative;
  max-width: 320px;
  background-color: #ffffff;
  border-radius: 22px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: rgb(55, 71, 104);
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}

.card1:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}

h3 {
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

.ant-menu-submenu-title{
  color:#ffffff !important;
}

.ant-card-head{
  padding:0px !important;
}

/* clock */

@import url(https://fonts.googleapis.com/css?family=Lato:300);


.container{
	text-align: center;
	/* background-color:rgb(55, 71, 104); */
	overflow: hidden;
}

.box:nth-child(2n-1){
	/* background-color:rgb(55, 71, 104); */
}

.box{
	display: inline-block;
	height: 200px;
	width: 33.3%;
	position: relative;
	/*margin:0 -4px -5px -2px;*/
	transition: all .2s ease;
  /* background-color:rgb(55, 71, 104); */
}



.box p{
	color: #777;
	font-family:  Lato,"Helvetica Neue" ;
	font-weight: 300;
	position: absolute;
	font-size: 20px;
	width: 100%;
	height: 25px;
	text-align: center;
	bottom: 0px;
	margin: 0;
	top:160px;
	background-color: #fff;
	opacity: 0;
	text-transform: uppercase;
	transition: all .2s ease;
}

.box:hover p{
	bottom:0px;
	top:175px;
	opacity: 1;
	transition: all .2s ease;
	z-index: 2;
}



/* MEDIA QUERIES */
@media (max-width: 700px){
	.box{
		width: 50%;
	}

	.box:nth-child(2n-1){
		background-color: inherit;
	}

	.box:nth-child(4n),.box:nth-child(4n-3) {
		background-color: rgba(0,0,0,0.05);
	}

}

@media (max-width: 420px){
	.box{
		width: 100%;
	}

	.box:nth-child(4n),.box:nth-child(4n-3){
		background-color: inherit;
	}

	.box:nth-child(2n-1){
		background-color:rgba(0,0,0,0.05);
	}

}


/* -------------- Clock -------------- */

.clock{
	border-radius: 60px;
	border: 3px solid rgb(55, 71, 104);
	height: 80px;
	width: 80px;
	position: relative;

	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);
}
.clock:after{
	content: "";
	position: absolute;
	background-color: rgb(55, 71, 104);
	top:2px;
	left: 48%;
	height: 38px;
	width: 4px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 97%;
			transform-origin: 50% 97%;
	-webkit-animation: grdAiguille 2s linear infinite;
			animation: grdAiguille 2s linear infinite;
}

@-webkit-keyframes grdAiguille{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes grdAiguille{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}

.clock:before{
	content: "";
	position: absolute;
	background-color:rgb(55, 71, 104);
	top:6px;
	left: 48%;
	height: 35px;
	width: 4px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 94%;
			transform-origin: 50% 94%;
	-webkit-animation: ptAiguille 12s linear infinite;
			animation: ptAiguille 12s linear infinite;
}

@-webkit-keyframes ptAiguille{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes ptAiguille{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}


/* -------------- Sablier -------------- */

.hourglass{
	position: relative;
	height: 80px;
	width: 80px;
	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);
	border: 3px solid #fff;
	border-radius: 80px;
	-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	-webkit-animation: hourglass 2s ease-in-out infinite;
			animation: hourglass 2s ease-in-out infinite;
}

.hourglass:before{
	content: "";
	position: absolute;
	top:8px;
	left: 18px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 37px 22px 0 22px;
	border-color: #fff transparent transparent transparent;
}
.hourglass:after{
	content: "";
	position: absolute;
	top: 35px;
	left: 18px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 22px 37px 22px;
	border-color: transparent transparent #fff transparent;
}

@-webkit-keyframes hourglass{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes hourglass{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(180deg);}
}

/* -------------- Loader1 -------------- */

.loader1{
	position: relative;
	height: 80px;
	width: 80px;
	border-radius: 80px;
	border: 3px solid  rgba(255,255,255, .7);

	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);

	-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	-webkit-animation: loader1 3s linear infinite;
			animation: loader1 3s linear infinite;
}

.loader1:after{
	content: "";
	position: absolute;
	top: -5px;
	left: 20px;
	width: 11px;
	height: 11px;
	border-radius: 10px;
	background-color: #fff;
}

@-webkit-keyframes loader1{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes loader1{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}


/* -------------- loader2 -------------- */

.loader2{
	position: relative;
	width: 5px;
	height: 5px;

	top: 49%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 2.5px);
	left: 49%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 2.5px);

	background-color: #fff;
	border-radius: 50px;
}

.loader2:before{
	content: "";
	position: absolute;
	top: -38px;
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	border-radius: 0 50px 0px  0;
	width: 40px;
	height: 40px;
	background-color: rgba(255, 255, 255, .1);
	-webkit-transform-origin:  0% 100%;
			transform-origin:  0% 100% ;
	-webkit-animation: loader2 1.5s linear infinite;
			animation: loader2 1.5s linear infinite;
}

.loader2:after{
	content: "";
	position: absolute;
	top: 2px;
	right: 2px;
	border-bottom: 3px solid #fff;
	border-left: 3px solid #fff;
	border-radius: 0 0px 0px  50px;
	width: 40px;
	height: 40px;
	background-color: rgba(255, 255, 255, .1);
	-webkit-transform-origin:  100% 0%;
			transform-origin:  100% 0% ;
	-webkit-animation: loader2 1.5s linear infinite;
			animation: loader2 1.5s linear infinite;
}



@-webkit-keyframes loader2{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes loader2{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}


/* -------------- loader3 -------------- */

.loader3{
	position: relative;
	width: 150px;
	height: 20px;

	top: 45%;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 25%;
	left: -webkit-calc(50% - 75px);
	left: calc(50% - 75px);
}

.loader3:after{
	content: "LOADING ...";
	color: #fff;
	font-family:  Lato,"Helvetica Neue" ;
	font-weight: 200;
	font-size: 16px;
	position: absolute;
	width: 100%;
	height: 20px;
	line-height: 20px;
	left: 0;
	top: 0;
	background-color: #e74c3c;
	z-index: 1;
}

.loader3:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top: -5px;
	left: 0px;
	height: 30px;
	width: 0px;
	z-index: 0;
	opacity: 1;
	-webkit-transform-origin:  100% 0%;
			transform-origin:  100% 0% ;
	-webkit-animation: loader3 10s ease-in-out infinite;
			animation: loader3 10s ease-in-out infinite;
}



@-webkit-keyframes loader3{
    0%{width: 0px;}
    70%{width: 100%; opacity: 1;}
    90%{opacity: 0; width: 100%;}
    100%{opacity: 0;width: 0px;}
}

@keyframes loader3{
    0%{width: 0px;}
    70%{width: 100%; opacity: 1;}
    90%{opacity: 0; width: 100%;}
    100%{opacity: 0;width: 0px;}
}

/* -------------- loader4 -------------- */

.loader4{
	position: relative;
	width: 150px;
	height: 20px;

	top: 45%;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 25%;
	left: -webkit-calc(50% - 75px);
	left: calc(50% - 75px);

	background-color: rgba(255,255,255,0.2);
}

.loader4:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top: 0px;
	left: 0px;
	height: 20px;
	width: 0px;
	z-index: 0;
	opacity: 1;
	-webkit-transform-origin:  100% 0%;
			transform-origin:  100% 0% ;
	-webkit-animation: loader4 10s ease-in-out infinite;
			animation: loader4 10s ease-in-out infinite;
}

.loader4:after{
	content: "LOADING ...";
	color: #fff;
	font-family:  Lato,"Helvetica Neue" ;
	font-weight: 200;
	font-size: 16px;
	position: absolute;
	width: 100%;
	height: 20px;
	line-height: 20px;
	left: 0;
	top: 0;
}

@-webkit-keyframes loader4{
    0%{width: 0px;}
    70%{width: 100%; opacity: 1;}
    90%{opacity: 0; width: 100%;}
    100%{opacity: 0;width: 0px;}
}

@keyframes loader4{
    0%{width: 0px;}
    70%{width: 100%; opacity: 1;}
    90%{opacity: 0; width: 100%;}
    100%{opacity: 0;width: 0px;}
}

/* -------------- loader5 -------------- */
.loader5{
	position: relative;
	width: 150px;
	height: 20px;

	top: 45%;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 25%;
	left: -webkit-calc(50% - 75px);
	left: calc(50% - 75px);

	background-color: rgba(255,255,255,0.2);
}

.loader5:after{
	content: "LOADING ...";
	color: #fff;
	font-family:  Lato,"Helvetica Neue" ;
	font-weight: 200;
	font-size: 16px;
	position: absolute;
	width: 100%;
	height: 20px;
	line-height: 20px;
	left: 0;
	top: 0;
}

.loader5:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top: 0px;
	height: 20px;
	width: 0px;
	z-index: 0;
	-webkit-transform-origin:  100% 0%;
			transform-origin:  100% 0% ;
	-webkit-animation: loader5 7s ease-in-out infinite;
			animation: loader5 7s ease-in-out infinite;
}

@-webkit-keyframes loader5{
    0%{width: 0px; left: 0px}
    48%{width: 100%; left: 0px}
    50%{width: 100%; right: 0px}
    52%{width: 100%; right: 0px}
    100%{width: 0px; right: 0px}
}

@keyframes loader5{
    0%{width: 0px; left: 0px}
    48%{width: 100%; left: 0px}
    50%{width: 100%; right: 0px}
    52%{width: 100%; right: 0px}
    100%{width: 0px; right: 0px}
}

/* -------------- loader6 -------------- */

.loader6{
	position: relative;
	width: 12px;
	height: 12px;

	top: 46%;
	top: -webkit-calc(50% - 6px);
	top: calc(50% - 6px);
	left: 46%;
	left: -webkit-calc(50% - 6px);
	left: calc(50% - 6px);
	
	border-radius: 12px;
	background-color: #fff;
	-webkit-transform-origin:  50% 50%;
			transform-origin:  50% 50% ;
	-webkit-animation: loader6 1s ease-in-out infinite;
			animation: loader6 1s ease-in-out infinite;
}

.loader6:before{
	content: "";
	position: absolute;
	background-color: rgba(255, 255, 255, .5);
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}

.loader6:after{
	content: "";
	position: absolute;
	background-color: rgba(255, 255 ,255 ,.5);
	top: 0px;
	left: 25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
}


@-webkit-keyframes loader6{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes loader6{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}

/* -------------- loader7 -------------- */

.loader7{
	position: relative;
	width: 40px;
	height: 40px;

	top: 40%;
	top: -webkit-calc(50% - 20px);
	top: calc(50% - 20px);
	left: 43%;
	left: -webkit-calc(50% - 20px);
	left: calc(50% - 20px);

	background-color: rgba(255, 255, 255, .2);
}

.loader7:before{
	content: "";
	position: absolute;
	background-color: #fff;
	height: 10px;
	width: 10px;
	border-radius: 10px;
	-webkit-animation: loader7 2s ease-in-out infinite;
			animation: loader7 2s ease-in-out infinite;
}

.loader7:after{
	content: "";
	position: absolute;
	background-color: #fff;
	top: 0px;
	left: 0px;
	height: 40px;
	width: 0px;
	z-index: 0;
	opacity: 1;
	-webkit-animation: loader72 10s ease-in-out infinite;
			animation: loader72 10s ease-in-out infinite;
}


@-webkit-keyframes loader7{
    0%{left: -12px; top: -12px;}
    25%{left:42px; top:-12px;}
    50%{left: 42px; top: 42px;}
    75%{left: -12px; top: 42px;}
    100%{left:-12px; top:-12px;}
}

@keyframes loader7{
    0%{left: -12px; top: -12px;}
    25%{left:42px; top:-12px;}
    50%{left: 42px; top: 42px;}
    75%{left: -12px; top: 42px;}
    100%{left:-12px; top:-12px;}
}

@-webkit-keyframes loader72{
    0%{width: 0px;}
    70%{width: 40px; opacity: 1;}
    90%{opacity: 0; width: 40px;}
    100%{opacity: 0;width: 0px;}
}

@keyframes loader72{
    0%{width: 0px;}
    70%{width: 40px; opacity: 1;}
    90%{opacity: 0; width: 40px;}
    100%{opacity: 0;width: 0px;}
}

/* -------------- loader8 -------------- */

.loader8{
	position: relative;
	width: 80px;
	height: 80px;

	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);

	border-radius: 50px;
	background-color: rgba(255, 255, 255, .2);
	border-width: 40px;
	border-style: double;
	border-color:transparent  #fff;

	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
		box-sizing:border-box;

	-webkit-transform-origin:  50% 50%;
		transform-origin:  50% 50% ;
	-webkit-animation: loader8 2s linear infinite;
		animation: loader8 2s linear infinite;
	
}




@-webkit-keyframes loader8{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes loader8{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}


/* -------------- loader9 -------------- */

.loader9:before{
	content: "";
	position: absolute;
	top: 0px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
	-webkit-animation: loader9g 3s ease-in-out infinite;
			animation: loader9g 3s ease-in-out infinite;
}

.loader9{
	position: relative;
	width: 12px;
	height: 12px;
	top: 46%;
	left: 46%;
	border-radius: 12px;
	background-color: #fff;
}


.loader9:after{
	content: "";
	position: absolute;
	top: 0px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
	-webkit-animation: loader9d 3s ease-in-out infinite;
			animation: loader9d 3s ease-in-out infinite;
}

@-webkit-keyframes loader9g{
	0%{	left: -25px; background-color: rgba(255, 255, 255, .8); }
	50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
	100%{ left:-25px; background-color: rgba(255, 255, 255, .8); }
}
@keyframes loader9g{
	0%{	left: -25px; background-color: rgba(255, 255, 255, .8); }
	50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
	100%{ left:-25px; background-color: rgba(255, 255, 255, .8); }
}


@-webkit-keyframes loader9d{
	0%{	left: 25px; background-color: rgba(255, 255, 255, .8); }
	50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
	100%{ left:25px; background-color: rgba(255, 255, 255, .8); }
}
@keyframes loader9d{
	0%{	left: 25px; background-color: rgba(255, 255, 255, .8); }
	50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
	100%{ left:25px; background-color: rgba(255, 255, 255, .8); }
}

/* -------------- loader10 -------------- */

.loader10:before{
	content: "";
	position: absolute;
	top: 0px;
	left: -25px;
	height: 12px;
	width: 12px;
	border-radius: 12px;
	-webkit-animation: loader10g 3s ease-in-out infinite;
			animation: loader10g 3s ease-in-out infinite;
}

.loader10{
	position: relative;
	width: 12px;
	height: 12px;
	top: 46%;
	left: 46%;
	border-radius: 12px;
	-webkit-animation: loader10m 3s ease-in-out infinite;
			animation: loader10m 3s ease-in-out infinite;
}


.loader10:after{
	content: "";
	position: absolute;
	top: 0px;
	left: 25px;
	height: 10px;
	width: 10px;
	border-radius: 10px;
	-webkit-animation: loader10d 3s ease-in-out infinite;
			animation: loader10d 3s ease-in-out infinite;
}

@-webkit-keyframes loader10g{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, 1);}
	50%{background-color: rgba(255, 255, 255, .2);}
	75%{background-color: rgba(255, 255, 255, .2);}
	100%{background-color: rgba(255, 255, 255, .2);}
}
@keyframes loader10g{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, 1);}
	50%{background-color: rgba(255, 255, 255, .2);}
	75%{background-color: rgba(255, 255, 255, .2);}
	100%{background-color: rgba(255, 255, 255, .2);}
}

@-webkit-keyframes loader10m{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, .2);}
	50%{background-color: rgba(255, 255, 255, 1);}
	75%{background-color: rgba(255, 255, 255, .2);}
	100%{background-color: rgba(255, 255, 255, .2);}
}
@keyframes loader10m{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, .2);}
	50%{background-color: rgba(255, 255, 255, 1);}
	75%{background-color: rgba(255, 255, 255, .2);}
	100%{background-color: rgba(255, 255, 255, .2);}
}

@-webkit-keyframes loader10d{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, .2);}
	50%{background-color: rgba(255, 255, 255, .2);}
	75%{background-color: rgba(255, 255, 255, 1);}
	100%{background-color: rgba(255, 255, 255, .2);}
}
@keyframes loader10d{
	0%{background-color: rgba(255, 255, 255, .2);}
	25%{background-color: rgba(255, 255, 255, .2);}
	50%{background-color: rgba(255, 255, 255, .2);}
	75%{background-color: rgba(255, 255, 255, 1);}
	100%{background-color: rgba(255, 255, 255, .2);}
}

.highlight-row {
    background-color: #faf9c4; 
}
