.tech-area {
  margin-top: 3px;
  margin-left: 5px;
  margin-bottom: 10px;
  /* float:left; */
  }

.chatbot-form{
  margin-left: 55px;
  margin-top: 35px;
  padding: 15px;
  padding-top: 20px;
  border-style:groove; 
  border-width: 1.9px;
  border-radius: 8%;
}