

.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.page-heading-- {
    &title {
      margin-top: 0;
      margin-bottom: 1.5625rem;
      color: #43484d;
      font-size: 2.375em;
    }
    
    &icon {
      fill: #c3cfd8;
      width: 3.125rem;
      height: 3.125rem;
      vertical-align: -0.75rem;
      margin-right: 1.125rem;
    }
    
    &sub-title {
      /* color: #5e6977; */
      color: aqua;
    }
  }

   /* 챗봇 */
   .react-chatbot-kit-chat-container {
    position: relative;
    width: auto;
    margin-left: 25px;
  }

  .react-chatbot-kit-chat-bot-message {
    margin-left: 3%;
    width:auto;
  }

  .react-chatbot-kit-chat-header {
    color: #43484d;
  }